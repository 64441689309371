<template>
  <div>
    <b-card body-class="p-0">
      <b-table-simple
        class="manual-time table"
        responsive
      >
        <thead>
          <tr>
            <th class="text-capitalize">
              {{ $t("Start Time") }}
            </th>
            <th class="text-capitalize">
              {{ $t("End Time") }}
            </th>
            <th class="text-capitalize">
              {{ $t("Duration") }}
            </th>
            <th class="text-capitalize">
              {{ $t("Remarks") }}
            </th>
            <th class="text-capitalize">
              {{ $t("Action") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(slots,index) of availableSlot"
            :key="index"
            :class="[ getBgColor(slots) ]"
            :set="slot = {
              start: slots.start,
              end: slots.end,
            }"
          >
            <td>  {{ FORMAT_DATE(slot.start, 'h:mm:ss A') }}</td>
            <td> {{ FORMAT_DATE(slot.end, 'h:mm:ss A') }}</td>
            <td>
              {{ CONVERT_HM(GET_TIME_DIFFERENCE(slot.start, slot.end)) }}
            </td>
            <td>
              {{ slots.working ? 'Working' : 'Not Working' }}
            </td>

            <td>
              <b-button
                v-if="slots.manual"
                variant="danger"
                class="btn-icon"
                @click="manualTimeToDelete = slots.manual"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
              <b-button
                v-if="!slots.working"
                v-b-tooltip.top="'Add Manual Time'"
                variant="outline-primary"
                class="btn-icon"
                @click="$emit('updateTime', slots)"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </td>
          </tr>
        </tbody>
      </b-table-simple>
      <confirm-delete
        :visible="!!manualTimeToDelete"
        :delete-params="{
          title: 'Delete Manual Time?',
          body: 'Are you sure you want to delete?'
        }"
        :processing="isProcessing"
        @close="manualTimeToDelete = null"
        @onConfirm="destroyManualTime"
      />
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BButton,
  BTableSimple,
} from 'bootstrap-vue'
import workPatternHelper from '@/utils/workPatternHelper'
import ConfirmDelete from '@/views/common/components/ConfirmModal.vue'
import useApollo from '@/plugins/graphql/useApollo'

export default {
  components: {
    BCard,
    BButton,
    BTableSimple,
    ConfirmDelete,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    selectedDateRange: {
      type: Object,
      default: () => {},
    },
    timezone: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      columns: [
        {
          field: 'start',
          label: 'Start time',
          thClass: 'bg-success text-white',
        },
        {
          field: 'end',
          label: 'End time',
          thClass: 'bg-success text-white',
        },
        {
          field: 'remarks',
          label: 'Remarks',
          thClass: 'bg-success text-white',
        },
        {
          field: 'action',
          label: 'Action',
          thClass: 'bg-success text-white',
        },
      ],
      availableSlot: [],
      manualTimeToDelete: null,
      isProcessing: false,
    }
  },
  mounted() {
    this.availableSlot = workPatternHelper(this.rows, this.selectedDateRange, this.timezone)
  },
  methods: {
    getBgColor(item) {
      if (item.manual) return 'bg-warning'
      return item.working ? 'bg-success-light' : ''
    },
    destroyManualTime() {
      this.isProcessing = true
      useApollo.activities.destroyManualTime({
        projectUid: localStorage.selected_project,
        activityUid: this.manualTimeToDelete.uuid,
      }).then(response => {
        this.manualTimeToDelete = null
        this.$emit('refetch')
        this.showSuccess(response.data?.deleteManualTime?.message)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
<style scoped>
 .bg-success-light {
   background-color: #2ee98173 !important;
 }
 .bg-warning td {
  color: #555 !important;
 }
</style>
