<template>
  <div>
    <b-card body-class="p-1">
      <filters
        v-model="filter"
        date-type="date"
        :multiple-user-select="false"
        @timezone-changed="updateTimezone"
      />
    </b-card>
    <b-tabs
      v-if="filter.userId"
      id="tour-manual-time"
      v-model="activeTab"
      class="position-relative"
      pills
    >
      <b-tab
        :title="$t('Manual Time')"
        @click="getWorkPattern"
      >
        <b-overlay :show="isProcessing">
          <slot-table
            v-if="filter.userId"
            :key="tableKey"
            :rows="workPatterns"
            :selected-date-range="selectedDateRange"
            :timezone="filter.time_zone"
            @updateTime="showTime"
            @refetch="refetch"
          />
        </b-overlay>
      </b-tab>
      <b-tab
        v-if="canApproveManualTime"
        :title="$t('Pending Approvals')"
        @click="getRequestedManual"
      >
        <b-overlay :show="isProcessing">
          <requested-time
            :requested-manual-time="requestedManualTime"
            @refetch="refetch"
          />
        </b-overlay>
      </b-tab>

      <template #tabs-end>
        <manage-permissions
          v-if="canManagePermissions"
          classes="position-absolute right-0 top-10 p-1"
          :permissions="permissions"
        />
      </template>
    </b-tabs>

    <update-time
      v-if="filter.userId"
      :visible="showUpdateTimeModal"
      :time-parms="timeParms"
      :user-id="filter.userId"
      :day="filter.date"
      :timezone="filter.time_zone"
      @onSave="refetch"
      @close="showUpdateTimeModal = false"
    />
  </div>
</template>
<script>
import {
  BCard, BOverlay, BTab, BTabs,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import { checkPermission } from '@/utils/permissions'
import ManagePermissions from '@/views/common/components/ManagePermissions.vue'
import EventBus from '@/event-bus'
import Filters from '@/views/common/components/Filter.vue'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { setComponentReady } from '@/views/tour/tour'
import UpdateTime from './components/UpdateTime.vue'
import SlotTable from './components/SlotTable.vue'
import RequestedTime from './components/RequestedTime.vue'

export default {
  components: {
    BCard,
    UpdateTime,
    SlotTable,
    BOverlay,
    BTab,
    BTabs,
    RequestedTime,
    ManagePermissions,
    Filters,
  },
  data() {
    return {
      rows: [],
      showUpdateTimeModal: false,
      filter: {
        userId: null,
        date: this.TODAY({ localTime: false }),
        time_zone: localStorage.timezone ?? 'Asia/Kathmandu',
      },
      workPatterns: [],
      tableKey: Math.random(),
      timeParms: {},
      isProcessing: false,
      selectedDateRange: {},
      activeTab: 0,
      requestedManualTime: {},
      permissions: ['timing.manual.approve'],
      canManagePermissions: this.$store.state.permissions.canManagePermissions.value,
      needPermissionCheck: false,
    }
  },
  computed: {
    ...mapGetters('project', ['GET_SELECTED_USER']),
    ...mapGetters('permissions', ['canApproveManualTime']),
    maxEndDate() {
      const startDate = `${this.filter.date} 00:00:00`
      if (moment(startDate).isSame(this.TODAY())) {
        return moment().format('YYYY-MM-DD HH:mm:00')
      }
      return this.ADD_DAYS(this.filter.date, 1, 'YYYY-MM-DD 00:00:00').start
    },
  },
  watch: {
    'filter.userId': {
      handler(val) {
        if (this.canApproveManualTime) this.getRequestedManual()
        if (val !== this.GET_SELECTED_USER) this.setSelectedUser(val)
      },
    },
    GET_SELECTED_USER: {
      handler(val) {
        if (val && val !== this.filter.userId) this.filter.userId = val
      },
      immediate: true,
    },
    filter: {
      handler() {
        if (this.filter.userId) {
          this.getWorkPattern()
        }
      },
      deep: true,
    },
  },
  mounted() {
    EventBus.$on('permission-updated', permission => {
      if (this.permissions.indexOf(permission) !== -1) {
        this.needPermissionCheck = true
        setTimeout(() => this.checkUserPermission(), 2000)
      }
    })
    this.checkUserPermission()
    this.getWorkPattern()
  },
  methods: {
    ...mapActions('project', ['setSelectedUser']),
    ...mapActions('permissions', ['setManualTimeApprovePermission']),
    checkUserPermission() {
      if (this.needPermissionCheck || !this.canApproveManualTime) {
        checkPermission(...this.permissions, permission => {
          const canApproveManualTime = permission['timing.manual.approve'] === true
          this.setManualTimeApprovePermission(canApproveManualTime)
          this.needPermissionCheck = false
        })
      }
    },
    refetch() {
      this.getWorkPattern()
      if (this.canApproveManualTime) this.getRequestedManual()
    },
    showTime(item) {
      this.showUpdateTimeModal = true
      this.timeParms = { ...item, start: this.FORMAT_DATE(item.start, 'HH:mm:ss'), end: this.FORMAT_DATE(item.end, 'HH:mm:ss') }
    },
    getWorkPattern() {
      this.isProcessing = true
      const startDate = `${this.filter.date} 00:00:00`
      const endDate = this.maxEndDate
      useApollo.activities.getWorkPattern({
        startTime: startDate,
        endTime: endDate,
        projectId: localStorage.selected_project,
        userId: this.filter.userId,
      }).then(response => {
        const activitiesData = response.data.me.activities.data
        activitiesData.sort((a, b) => new Date(a.startedAt) - new Date(b.startedAt))
        const patterns = activitiesData.map(pattern => ({
          start: this.FORMAT_DATE(pattern.startedAt, 'YYYY-MM-DD HH:mm:ss'),
          end: this.FORMAT_DATE(pattern.endedAt, 'YYYY-MM-DD HH:mm:ss'),
          isManual: !!pattern.manualDuration,
          uuid: pattern.uuid,
        }))
        this.workPatterns = [...patterns]
        this.selectedDateRange = {
          start: startDate,
          end: endDate,
        }
        this.tableKey = Math.random()
        setComponentReady()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => { this.isProcessing = false })
    },
    getRequestedManual() {
      this.isProcessing = true
      useApollo.activities.getPendingManualTime({
        projectId: this.$store.state.project.selectedProject,
        userId: this.filter.userId,
      }).then(response => {
        this.requestedManualTime = response.data?.project?.pendingManualTimes
      }).finally(() => {
        this.isProcessing = false
      })
    },
    updateTimezone() {
      if (this.isAfterTODAY(this.filter.date)) this.filter.date = this.TODAY({ localTime: false })
    },
  },
}
</script>

<style lang="scss">
@media screen and (max-width: 768px) {
  #tour-manual-time {
    .nav-pills .nav-link {
      padding: 0.5rem 0.75rem;
    }
    .top-10 {
      top: -10px;
    }
  }
}
</style>
