var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{attrs:{"body-class":"p-0"}},[_c('b-table-simple',{staticClass:"manual-time table",attrs:{"responsive":""}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t("Start Time"))+" ")]),_c('th',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t("End Time"))+" ")]),_c('th',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t("Duration"))+" ")]),_c('th',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t("Remarks"))+" ")]),_c('th',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t("Action"))+" ")])])]),_c('tbody',_vm._l((_vm.availableSlot),function(slots,index){return _c('tr',{key:index,class:[ _vm.getBgColor(slots) ],attrs:{"set":_vm.slot = {
            start: slots.start,
            end: slots.end,
          }}},[_c('td',[_vm._v(" "+_vm._s(_vm.FORMAT_DATE(_vm.slot.start, 'h:mm:ss A')))]),_c('td',[_vm._v(" "+_vm._s(_vm.FORMAT_DATE(_vm.slot.end, 'h:mm:ss A')))]),_c('td',[_vm._v(" "+_vm._s(_vm.CONVERT_HM(_vm.GET_TIME_DIFFERENCE(_vm.slot.start, _vm.slot.end)))+" ")]),_c('td',[_vm._v(" "+_vm._s(slots.working ? 'Working' : 'Not Working')+" ")]),_c('td',[(slots.manual)?_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"danger"},on:{"click":function($event){_vm.manualTimeToDelete = slots.manual}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1):_vm._e(),(!slots.working)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top",value:('Add Manual Time'),expression:"'Add Manual Time'",modifiers:{"top":true}}],staticClass:"btn-icon",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$emit('updateTime', slots)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1):_vm._e()],1)])}),0)]),_c('confirm-delete',{attrs:{"visible":!!_vm.manualTimeToDelete,"delete-params":{
        title: 'Delete Manual Time?',
        body: 'Are you sure you want to delete?'
      },"processing":_vm.isProcessing},on:{"close":function($event){_vm.manualTimeToDelete = null},"onConfirm":_vm.destroyManualTime}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }